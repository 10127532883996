<template>
  <div class="admin-view-container" style="height: auto" v-loading="loading">
    <div class="admin-table-search head" style="display: block">
      <i class="el-icon-s-order"></i>
      驾校数据
      <el-tooltip class="item" effect="light" content="今日数据：次日凌晨1点进行更替" placement="right">
        <i class="el-icon-question"></i>
      </el-tooltip>
    </div>
    <!-- 会员服务 -->
    <div class="plan content">
      <div class="content-tag">会员服务</div>
      <div class="plan-balance">
        <div class="plan-balance-head">账户余额(元)</div>
        <div class="plan-balance-main">{{ tableData.remainingSum || 0 }}</div>
        <div class="plan-balance-bottom"></div>
        <div class="plan-balance-bottom">锁定余额：{{ tableData.lockRemainingSum || 0 }}(元)</div>
      </div>
      <div class="plan-detail">
        <div class="plan-detail-flex">
          <div class="plan-detail-head">今日会员服务(元)</div>
          <div class="plan-detail-main">{{ tableData.plans1.todayPlan || 0 }}</div>
          <div class="plan-detail-bottom">昨日：{{ tableData.plans1.yesterdayPlan || 0 }}</div>
        </div>

        <div class="plan-detail-flex">
          <div class="plan-detail-head">本月会员服务(元)</div>
          <div class="plan-detail-main">{{ tableData.plans1.monthThePlan || 0 }}</div>
          <div class="plan-detail-bottom">上月：{{ tableData.plans1.lastMonthThePlan || 0 }}</div>
        </div>

        <div class="plan-detail-flex">
          <div class="plan-detail-head">本年会员服务(元)</div>
          <div class="plan-detail-main">{{ tableData.plans1.yearsThePlan || 0 }}</div>
          <div class="plan-detail-bottom">去年：{{ tableData.plans1.lastYearsThePlan || 0 }}</div>
        </div>

        <div class="plan-detail-flex">
          <div class="plan-detail-head">今日补偿(元)</div>
          <div class="plan-detail-main">{{ tableData.plans2.todayPlan || 0 }}</div>
          <div class="plan-detail-bottom">昨日：{{ tableData.plans2.yesterdayPlan || 0 }}</div>
        </div>

        <div class="plan-detail-flex">
          <div class="plan-detail-head">本月补偿(元)</div>
          <div class="plan-detail-main">{{ tableData.plans2.monthThePlan || 0 }}</div>
          <div class="plan-detail-bottom">上月：{{ tableData.plans2.lastMonthThePlan || 0 }}</div>
        </div>

        <div class="plan-detail-flex">
          <div class="plan-detail-head">本年补偿(元)</div>
          <div class="plan-detail-main">{{ tableData.plans2.yearsThePlan || 0 }}</div>
          <div class="plan-detail-bottom">去年：{{ tableData.plans2.lastYearsThePlan || 0 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {schHomeReport} from '../../api/index';
import './home.scss';
export default {
  data() {
    return {
      loading: false,
      tableData: {
        todayTheAdmissions: 0, //	今日招生
        yesterdayTheAdmissions: 0, //	昨日招生
        monthTheAdmissions: 0, //	本月招生
        lastMonthTheAdmissions: 0, //	上月招生
        yearsTheAdmissions: 0, //	本年招生
        lastYearsTheAdmissions: 0, //	去年招生
        remainingSum: 0, //	账户余额
        lockRemainingSum: 0, //	锁定余额
        orderFlowAmount: 0, //	上次充值金额
        plans: [], // - planType 1：会员服务  2：补偿
        plans1: {}, // - planType 1：会员服务
        plans2: {}, // - planType 2：补偿
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name == 'login') {
        if (Date.parse(new Date()) / 1000 > vm.$store.state.user.drvSchoolEndTime) {
          var date = new Date(vm.$store.state.user.drvSchoolEndTime * 1000);
          var years = date.getFullYear();
          var month = date.getMonth() + 1;
          var day = date.getDate();
          vm.$message({
            showClose: true,
            message: `会员服务业务已${years}/${month}/${day}于结束服务，不可提交新的会员服务业务，已添加业务不受影响，可以继续提交赔付。`,
            type: 'error',
            duration: 300000,
          });
        }
        schHomeReport().then((res) => {
          if (res.data.code == 0) {
            vm.tableData.remainingSum = res.data.data.remainingSum;
            vm.tableData.lockRemainingSum = res.data.data.lockRemainingSum;
            vm.balanceLess();
          } else {
            vm.$message.error(res.data.msg);
            return false;
          }
        });
      }
    });
  },
  created() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.loading = true;
      schHomeReport().then((res) => {
        this.loading = false;
        if (res.data.code == 0) {
          res.data.data.plans.forEach((item) => {
            if (item.planType == 1) {
              res.data.data['plans1'] = item;
            } else if (item.planType == 2) {
              res.data.data['plans2'] = item;
            }
          });
          Object.assign(this.tableData, res.data.data);
        } else {
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },

    async balanceLess() {
      if (this.tableData.remainingSum >= 300) {
        return false;
      }
      let vNode = this.$createElement((await import('./balance-less.vue')).default, {
        key: Math.random(),
        props: {
          num: this.tableData.remainingSum,
        },
      });

      this.$adminDialog(vNode, {
        title: '提示',
        width: '500px',
      });
    },
  },
};
</script>

<style lang="scss">
</style>
